import React from "react";
import { Link } from "gatsby";
import * as styles from "styles/navbar.module.css"

const NavBar = () => {
    return (
      <nav className={styles.navbar}>
        <ul className={styles.navbar__ul}>
          <li className={styles.navbar__li}>
            <Link to="/">top</Link>
          </li>
          <li className={styles.navbar__li}>
            <Link to="/about_us">about us</Link>
          </li>
          <li className={styles.navbar__li}>
            <Link to="/works">works</Link>
          </li>
          <li className={styles.navbar__li}>
            <Link to="/contact_us">contact us</Link>
          </li>
        </ul>
      </nav>
    );
  };
  
  export default NavBar;