import React from 'react'
import * as styles from 'styles/footer.module.css'

const Footer = () => (

<footer>
(c) {new Date().getFullYear()} magurochan, Built with
{` `}
<a href="https://www.gatsbyjs.com">Gatsby</a>
</footer>

)
export default Footer